<template>
    <div class="pt-container">
      <div class="pt-header">
        <div class="pt-title">
          Movimentações
          <span id="help-icon" class="icon-box">
            <HelpCircle class="icon stroke no-print"/>
          </span>
  
          <b-tooltip target="help-icon" placement="bottom">
            Confira e gerencie todas as movimentações entre suas contas.
          </b-tooltip>
        </div>
  
        <div class="gap-action-buttons no-print">
          <Loading :class="{ reload: true, loading: loading }" @click="() => !loading && getMovements()"/>
          <b-button
            class="wh-button"
            variant="outline-primary"
            outline
            @click="getXlsx"
          >
            Download xlsx
          </b-button>
  
          <b-button
            v-can="'FiMov2'"
            variant="primary"
            @click="$bvModal.show('movement-modal')"
          >
            Nova movimentação
          </b-button>
        </div>
      </div>

      <MovementsResume :bankAccounts="bankAccounts" />
  
      <div class="row">
        <div class="col-lg-3 col-md-6">
            <b-form-group class="form-group">
                <label>Período</label>
                <Periods v-model="filters.period" @select="handlePeriod" />
            </b-form-group>
        </div>
        <div class="col-lg-3 col-md-6">
            <b-form-group class="form-group">
                <label>Conta origem</label>
                <Select v-model="filters.origin_id" :options="bankAccounts" />
            </b-form-group>
        </div>
        <div class="col-lg-3 col-md-6">
            <b-form-group class="form-group">
                <label>Método de operação</label>
                <Select v-model="filters.method" :options="methods" />
            </b-form-group>
        </div>
        <div class="col-lg-3 col-md-6">
            <b-form-group class="form-group">
                <label>Conta destino</label>
                <Select v-model="filters.destination_id" :options="bankAccounts"/>
            </b-form-group>
        </div>
      </div>
  
      <MovementsTable 
        :loading="loading"
        :movements="movements"
        :onEdit="onEditMovement"
      />

      <div class="pagination-position no-print">
        <b-pagination
          size="sm"
          v-model="page"
          :total-rows="count"
          :per-page="limit"
          first-number
          last-number
        />
      </div>

      <MovementModal 
        :movement="movement"
        :bankAccounts="bankAccounts"
        @hidden="hiddenMovementModal"
      />
    </div>
  </template>
  
  <script>
  import api from '../api'
  import { saveAs } from 'file-saver';
  import { parseNumberToMoney } from '@/utils/moneyHelper'
  import { getCurrentClinic } from '@/utils/localStorageManager'
  
  export default {
    name: 'movements-tab',
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        HelpCircle: () => import('@/assets/icons/help-circle.svg'),
        MovementsTable: () => import('@/modules/financial/components/MovementsTable'),
        Periods: () => import('@/components/General/Periods'),
        Select:() => import('@/components/Select'),
        MovementModal: () => import('@/modules/financial/modals/MovementModal'),
        MovementsResume: () => import('@/modules/financial/components/MovementsResume'),
    },
    async mounted() {
        this.getBankAccounts()
        this.getMovements()
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        loading: false,
        bankAccounts: [],
        movements: [],
        movement: null,
        query: '',
        page: 1,
        limit: 0,
        count: 0,
        filters: this.getDefaultFilters(),
        methods: [
          { label: 'Selecionar', value: null },
          { label: 'PIX', value: 'PIX' },
          { label: 'TED', value: 'TED' },
        ],
      }
    },
    methods: {
        parseNumberToMoney,
        hiddenMovementModal() {
            this.movement = null
            this.getBankAccounts()
            this.getMovements()
        },
        onEditMovement(movement) {
            this.movement = movement
            setTimeout(() => {
              this.$bvModal.show('movement-modal')
      }, 500);
        },
        getBankAccounts() {
            api.getBankAccounts(this.clinic.id)
            .then(({ data }) => {
                this.bankAccounts = data.map(bankAccount => ({
                    ...bankAccount,
                    label: bankAccount.name,
                    value: bankAccount.id,
                }))
                this.bankAccounts.unshift({ label: 'Todas as contas', value: null })
            })
        },
        getMovements() {
            this.loading = true
            api.getFinancialMovements(this.clinic.id, this.page, this.filters)
            .then(({ data }) => {
                this.movements = data.data
                this.limit = data.per_page
                this.count = data.count
            })
            .catch((err) => this.$toast.error(err.message))
            .finally(() => (this.loading = false))
        },
        getXlsx() {
            this.loading = true
            api.getMovementsExport(this.clinic.id, this.filters)
            .then(res => {
                saveAs(new Blob([res.data], { type: 'application/vnd.ms-excel' }),'movimentacoes.xlsx')
            })
            .catch(err => this.$toast.error(err.message))
            .finally(() => (this.loading = false))
        },
        handlePeriod(period) {
            this.filters.period.start = period.start
            this.filters.period.end = period.end
        },
        getDefaultFilters: () => ({
            period: {
                start: null,
                end: null,
            },
            origin_id: null,
            method: null,
            destination_id: null,
        }),
    },
    watch: {
        'filters.period': {
            handler: function () {
                this.getMovements()
            },
            deep: true,
        },
        'filters.origin_id': {
            handler: function () {
                this.getMovements()
            },
            deep: true,
        },
        'filters.method': {
            handler: function () {
                this.getMovements()
            },
            deep: true,
        },
        'filters.destination_id': {
            handler: function () {
                this.getMovements()
            },
            deep: true,
        },
    },
  }
  </script>
  
  <style scoped lang="scss">
  .pt-container {
    padding: 30px 10px;
  
    .pt-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
  
      .gap-action-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }
  
    .pt-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }
  
    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
  
    .pagination-position {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: end;
      padding: 20px 0 !important;
    }
    .amount-position {
      display: flex;
      flex-direction: row-reverse;
  
      .toPay {
        background: var(--light-orange-200);
      }
  
      .toReceive {
        background: var(--states-success-soft-green);
      }
  
      div {
        padding: 8px 16px;
        border-radius: 8px;
        margin-left: 10px;
        justify-content: center;
  
        p {
          font-size: 16px;
          line-height: 150%;
          color: var(--type-active);
  
          span {
            font-weight: 700;
            color: var(--blue-500);
          }
        }
      }
    }
  
    label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: var(--type-active);
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }
  
    .label {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: minmax(auto, 24px);
  
      .status {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
  
        .status-input {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
        }
      }
    }
  
    #input-title {
      font-family: 'Nunito Sans' !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 150% !important;
      color: var(--type-active);
    }
  }
  
  .wh-button {
    color: var(--blue-500);
  }
  .order-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: var(--type-active);
  }
  </style>
  